// src/utils/sanityClient.js

import { createClient } from "@sanity/client";

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true, // `true` if you want to use cached data
  apiVersion: process.env.REACT_APP_SANITY_API_VERSION,
});
