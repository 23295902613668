// A component that displays details of a film event, including its poster,
// title, director, date, and venue or online status, formatted appropriately.

import React from "react";
import { formatDate, formatTime } from "../utils/dateUtils";
import { FaGlobe, FaCalendar, FaClock, FaLocationDot } from "react-icons/fa6";

const FilmCard = ({ film }) => {
  const filmDate = new Date(film.date || film.online_date);
  const hasEventEnded = filmDate < new Date();

  const currentYear = new Date().getFullYear();
  const includeYear = filmDate.getFullYear() !== currentYear;

  const cardColor = film.city ? film.city.color.hex : "#FFFFFF";

  return (
    <div className="film-card " style={{ backgroundColor: cardColor }}>
      {film.poster && (
        <div className="film-poster-mask">
          <img
            src={film.poster}
            alt={`${film.title} Poster`}
            className="film-poster"
          />
        </div>
      )}
      <section class="screening-details">
        <div className="date">
          <FaCalendar size={16} /> {formatDate(film.date, includeYear)}
        </div>
        <div class="film-details">
          <span class="film-theme">{film.theme}</span>
          <h3 class="film-title">{film.title}</h3>
          <span>{film.director}</span>
        </div>
        {film.note && <span className="film-note">{film.note}</span>}
        <div class="venue-details">
          <div class="date-time-details">
            <span className="chip">
              <FaClock size={12} /> {formatTime(film.date)}
            </span>
          </div>
          {film.online_date ? (
            <span className="chip">
              <FaGlobe size={12} /> Online Event
            </span>
          ) : film.location ? (
            <div class="venue-location">
              <FaLocationDot size={14} />{" "}
              <a href={film.location.url} aria-label="Venue">
                {film.location.name}, {film.city.city}
              </a>{" "}
            </div>
          ) : (
            <p>No Venue Information</p>
          )}
          {!hasEventEnded && film.rsvpLink && (
            <a href={film.rsvpLink} target="_blank" rel="noopener noreferrer">
              <button className="rsvp-button">Get Tickets</button>
            </a>
          )}
        </div>
      </section>
    </div>
  );
};

export default FilmCard;
