import React, { useState, useEffect } from "react";
import FilmCard from "./FilmCard";

const FilmList = ({ films, initialPastEvents }) => {
  const [visiblePastEvents, setVisiblePastEvents] = useState(initialPastEvents);

  // Sets the initial number of past events to show
  useEffect(() => {
    setVisiblePastEvents(initialPastEvents);
  }, [initialPastEvents]);

  if (!films || films.length === 0) {
    return <p>No films available.</p>;
  }

  const now = new Date();

  // Filters upcoming films based on current date
  const upcomingFilms = films
    .filter((film) => {
      const filmDate = new Date(film.date || film.online_date);
      return filmDate >= now;
    })
    .sort(
      (a, b) =>
        new Date(a.date || a.online_date) - new Date(b.date || b.online_date),
    );

  // Filters past films based on current date
  const pastFilms = films
    .filter((film) => {
      const filmDate = new Date(film.date || film.online_date);
      return filmDate < now;
    })
    .sort(
      (a, b) =>
        new Date(b.date || b.online_date) - new Date(a.date || a.online_date),
    );

  // Handles the case where the user clicks the "Show More" button
  const handleShowMore = () => {
    setVisiblePastEvents((prev) => prev + 8);
  };

  // Renders the list of films
  return (
    <div className="film-list-container">
      {upcomingFilms.length > 0 && (
        <section className="upcoming-events">
          <h2>Upcoming Events</h2>
          <div className="film-grid">
            {upcomingFilms.map((film) => (
              <FilmCard key={film._id} film={film} />
            ))}
          </div>
        </section>
      )}
      {pastFilms.length > 0 && (
        <section className="past-events">
          <h2>Past Events</h2>
          <div className="film-grid">
            {/* Calculates the number of past events to show */}
            {pastFilms.slice(0, visiblePastEvents).map((film) => (
              <FilmCard key={film._id} film={film} />
            ))}
          </div>
          {/* Shows the "Show More" button if there are more past events */}
          {pastFilms.length > visiblePastEvents && (
            <button
              onClick={handleShowMore}
              className="show-more-button button-secondary"
            >
              Show more...
            </button>
          )}
        </section>
      )}
    </div>
  );
};

export default FilmList;
