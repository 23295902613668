// A component that provides a set of dropdown filters for location, month,
// and year, allowing users to narrow down the list of film events. It handles
// changes to the selected filters and updates the state accordingly.

import React from "react";
import FilterDropdown from "./FilterDropdown";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";

const Filters = ({ filters, setFilters, cities }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const cityOptions = cities.map((city) => ({
    value: city.city,
    label: city.city,
  }));
  cityOptions.push({ value: "online", label: "Online Events" });

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((month) => ({ value: month, label: month }));

  const yearOptions = Array.from(
    { length: new Date().getFullYear() - 2021 + 2 },
    (_, i) => 2021 + i,
  ).map((year) => ({ value: year, label: year.toString() }));

  return (
    <div className="filters">
      <div className="filter-container-location">
        <div className="filter-box-label">
          <FaLocationDot size={17} /> Location
        </div>
        <FilterDropdown
          label="Location"
          name="city"
          options={cityOptions}
          value={filters.city}
          onChange={handleChange}
        />
      </div>
      <div className="filter-container-date">
        <div className="filter-box-label">
          <FaCalendarDays size={17} /> Date
        </div>
        <FilterDropdown
          label="Month"
          name="month"
          options={monthOptions}
          value={filters.month}
          onChange={handleChange}
        />
        <FilterDropdown
          label="Year"
          name="year"
          options={yearOptions}
          value={filters.year}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Filters;
