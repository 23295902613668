import React, { useState, useEffect } from "react";
import { client } from "./utils/sanityClient";
import Filters from "./components/Filters";
import FilmList from "./components/FilmList";
import "./App.css";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const App = () => {
  const [films, setFilms] = useState([]);
  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    venue: "",
    city: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    // Fetches the list of films from Sanity
    const fetchFilms = async () => {
      const query = `*[_type == "event"]{
        _id,
        title,
        date,
        online_date,
        director,
        note,
        "theme": theme->theme,
        "poster": poster.asset->url,
        "city": city->{city, color},
        "location": location->{name, url},
        rsvpLink
      }`;
      const data = await client.fetch(query);
      setFilms(data);
    };

    // Fetches the list of cities from Sanity
    const fetchCities = async () => {
      const query = `*[_type == "city"]{_id, city, color}`;
      const data = await client.fetch(query);
      setCities(data);
    };

    fetchFilms();
    fetchCities();
  }, []);

  // Filters the list of films based on the selected filters
  const filteredFilms = films.filter((film) => {
    const { venue, city, month, year } = filters;
    const filmDate = new Date(film.date || film.online_date);
    const filmMonth = filmDate.toLocaleString("default", { month: "long" });
    const filmYear = filmDate.getFullYear();
    const filmCityName = film.city ? film.city.city : null;
    const filmLocation = film.location ? film.location.name : null;

    return (
      (venue === "" || filmLocation === venue) &&
      (city === "" ||
        (city === "online" ? !film.date : filmCityName === city)) &&
      (month === "" || filmMonth === month) &&
      (year === "" || filmYear.toString() === year)
    );
  });

  return (
    <div className="App">
      <div className="filter-container">
        <Filters filters={filters} setFilters={setFilters} cities={cities} />
      </div>
      <FilmList
        films={filteredFilms}
        initialPastEvents={filters.month || filters.year ? 8 : 4}
      />
    </div>
  );
};

export default App;
