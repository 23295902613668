// A component that renders a labeled dropdown menu for filtering options,
// allowing users to select from various values passed as props.

import React, { useState, useEffect, useRef } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendar } from "react-icons/fa6";

const FilterDropdown = ({ label, name, options, value, onChange }) => {
  const [showLabel, setShowLabel] = useState(value === "");
  const selectRef = useRef(null);

  useEffect(() => {
    const updateSelectStyle = () => {
      if (selectRef.current) {
        if (value !== "") {
          selectRef.current.classList.add("active");
        } else {
          selectRef.current.classList.remove("active");
        }
      }
    };

    updateSelectStyle();
  }, [value]);

  useEffect(() => {
    setShowLabel(value === "");
  }, [value]);

  const handleChange = (event) => {
    onChange(event);
    setShowLabel(event.target.value === "");
  };

  return (
    <label className="filter-dropdown">
      <span className="filter-label">{label}</span>
      <div className="select-wrapper">
        <select
          ref={selectRef}
          id={name}
          name={name}
          onChange={handleChange}
          value={value}
          className={`${name} filter-select ${
            !showLabel ? "" : "filter-option-hide"
          }`}
        >
          <option value="">All</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {showLabel && <span className="select-label">{label}</span>}
      </div>
    </label>
  );
};

export default FilterDropdown;
