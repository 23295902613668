// src/utils/dateUtils.js
// ----------------------
// A utility file that provides functions for formatting dates, including converting
// timestamps to a human-readable format, optionally showing the time.

export function formatDate(date, includeYear = false) {
  const tempDate = new Date(date);
  let options = {
    month: "short",
    day: "2-digit",
    year: includeYear ? "numeric" : undefined,
  };
  return new Intl.DateTimeFormat("en-UK", options).format(tempDate);
}

export function formatTime(date) {
  const tempTime = new Date(date);
  let options = {
    timeStyle: "short",
  };
  return new Intl.DateTimeFormat("en-IN", options).format(tempTime);
}
